import React from "react"

import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="Contact Web design & web development near me - Vermont Web Development"
        description="Need more sales? Let me design a new website that converts customers into buyers with ease. Book a call today, and we'll set up a meeting to help you improve your business!"
      />
      <main>
        <Contact />
      </main>
    </Layout>
  )
}

export default ContactPage
